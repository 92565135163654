import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Drawer, Dropdown, Menu, RadioChangeEvent, Select } from 'antd';
import { ArrowLeftOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { useMediaQuery } from 'react-responsive';

// import { downloadFile } from '../../utils/downloadFile';
import { StoreStatisticsTable } from './components/StoreStatisticsTable';
import StoresService from '../../services/stores';
import { CustomFilter } from '../../components/filter/CustomFilter';
import { RadioGroupMenu } from '../../components/RadioGroupMenu/RadioGroupMenu';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import { openNotification } from '../../utils/lastThreeMonths';
import styles from './styles.module.scss';

const ExportMenuItems = (onDownload: (type: 'sales' | 'detailed') => void) => (
  <Menu>
    <Menu.Item key="sales">
      <div onClick={() => onDownload('sales')}>By barcode</div>
    </Menu.Item>
    <Menu.Item key="detailed">
      <div onClick={() => onDownload('detailed')}>Detailed sales</div>
    </Menu.Item>
  </Menu>
);

export const StoreStatisticsPage = () => {
  const location = useLocation();
  const { type, typeOfPage } = useParams<{ type: string; typeOfPage: 'company' | 'brand' | 'barcode' }>();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState<'company' | 'brand' | 'barcode'>('company');
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);

  const onChangePage = ({ target: { value } }: RadioChangeEvent) => {
    setPage(value);
    navigate({ pathname: `/stores-statistics/${type}/${value}`, search: `${location.search}` });
  };

  const handleGoBack = () => {
    navigate({ pathname: `/stores`, search: location.search });
  };

  useEffect(() => {
    if (location.pathname.includes('company')) {
      setPage('company');
    } else if (location.pathname.includes('brand')) {
      setPage('brand');
    } else if (location.pathname.includes('barcode')) {
      setPage('barcode');
    }
    return () => {
      setPage('company');
    };
  }, [location.pathname]);

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const onSelectPageName = (pageName) => {
    navigate({ pathname: `/stores-statistics/sales/${pageName}`, search: `${location.search}` });
  };

  const handleDownloadFile = (type: 'sales' | 'detailed') => {
    setExportLoading(true);
    if (type === 'detailed') {
      StoresService.exportDetailedStoresStatistics({
        period_after: params?.period_after || '2023-11-01',
        period_before: params?.period_before || '2023-11-30',
        hashed_store_id: params?.hashed_store_id,
        mode: params?.mode,
        ...params,
      })
        .then((res) => {
          setIsOpenDonwloads(true);
          openNotification('File added to downloads folder', 'success');
          // downloadFile(res.data, `report_detailed`);
        })
        .finally(() => setExportLoading(false));
    } else {
      StoresService.exportStoresStatistics(page === 'company' ? 'local_company' : page, {
        period_after: params?.period_after || '2023-11-01',
        period_before: params?.period_before || '2023-11-30',
        hashed_store_id: params?.hashed_store_id,
        mode: params?.mode,
        ...params,
      })
        .then((res) => {
          setIsOpenDonwloads(true);
          openNotification('File added to downloads folder', 'success');
          // downloadFile(res.data, `report_sales_${page}`);
        })
        .finally(() => setExportLoading(false));
    }
  };

  return (
    <div className={styles.company_layout_content}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button icon={<ArrowLeftOutlined />} onClick={handleGoBack}>
          Back
        </Button>
      </div>
      <div className={styles.title} style={{ marginTop: 16 }}></div>
      <div className={styles.top_bar}>
        <div className={styles.wrapper}>
          {!isTabletOrMobile && (
            <>
              <RadioGroupMenu onChange={onChangePage} value={page} />
              <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                <CustomFilter pageName="stores" type={'barcode'} onFilterChange={onFilterChange} onCancel={hide} />
              </Drawer>
              &nbsp;&nbsp;&nbsp;
              {/* <Button
                type="ghost"
                className={styles.company_filter_btn}
                onClick={onOpenFilter}
                icon={<FilterOutlined />}
              >
                &nbsp;&nbsp;Filter
              </Button> */}
            </>
          )}
          {isTabletOrMobile && (
            <>
              {' '}
              <Select
                defaultValue="company"
                style={{ width: 120 }}
                bordered={false}
                onSelect={onSelectPageName}
                options={[
                  {
                    value: 'company',
                    label: 'Company',
                  },
                  {
                    value: 'brand',
                    label: 'Brand',
                  },
                  {
                    value: 'barcode',
                    label: 'Barcode',
                  },
                ]}
              />
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter pageName="stores" type={'barcode'} onFilterChange={onFilterChange} onCancel={hide} />
                </Drawer>
                &nbsp;&nbsp;&nbsp;
                {/* <Button
                  type="ghost"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                /> */}
              </div>
            </>
          )}
        </div>
        {page === 'barcode' && (
          <Dropdown overlay={ExportMenuItems(handleDownloadFile)}>
            <Button type="default" className={styles.export_btn} loading={exportLoading}>
              <VerticalAlignBottomOutlined />
              Export
            </Button>
          </Dropdown>
        )}
        {page !== 'barcode' && (
          <Button
            type="default"
            className={styles.export_btn}
            onClick={() => handleDownloadFile('sales')}
            loading={exportLoading}
          >
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}></div>
      <StoreStatisticsTable pageName={typeOfPage} />
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </div>
  );
};
