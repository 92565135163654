import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { AxiosError } from 'axios';
import { notification, PaginationProps, Table, Typography } from 'antd';
import { useQuery } from 'react-query';
import qs from 'qs';

import OutOfStockService from '../../../services/outOfStock';
import styles from './styles.module.scss';
import { useColumns } from './useColumns';

const { Text } = Typography;

type Props = {
  rule?: any;
};

export const OutOfStockTable = ({ rule }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [oosTotal, setOosTotal] = useState<string>('');
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    navigate({ pathname: location.pathname, search: queryParams.toString() });
  };

  const { data, isFetching, error } = useQuery(['outOfStockTable', location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    return OutOfStockService.getOutOfStockByBarcode(
      { local_company: params.local_company, months: params.motnhs, ...params },
      { signal }
    );
  });

  // const getData = useCallback(() => {
  //   queryClient.refetchQueries('trendsTable', { active: true });
  // }, [queryClient]);

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
      setOosTotal(data?.data?.additional);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification('Fill required fields');
        setTableData([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
      }
    }

    return () => {
      setTableData([]);
      setTotal(0);
    };
  }, [data, error]);

  const columns = useColumns({ isTabletOrMobile });

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isFetching}
        pagination={{
          pageSize: limit,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
        className={styles.ant_table}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                {oosTotal && (
                  <>
                    <Table.Summary.Cell index={0}>
                      <Text className={styles.total_bold}>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} />
                    <Table.Summary.Cell index={2}>
                      <Text className={styles.total_bold}>{oosTotal}</Text>
                    </Table.Summary.Cell>
                  </>
                )}
              </Table.Summary.Row>
            </>
          );
        }}
        sticky
        scroll={{ x: '100%' }}
        bordered
        size="middle"
      />
    </>
  );
};
