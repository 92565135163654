import { useCallback, useEffect, useMemo, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import qs from 'qs';

import { AuthStatus } from './services/auth';
import { useAuthService } from './context/authContext';
import { Profile } from './models/authModel';
import { Loader } from './components/loader/Loader';
import { Login } from './pages/login/login';
import { ForgetPassword } from './pages/login/ForgetPassword';
import { RestorePassword } from './pages/login/RestorePassword';
import { PrivateRoute } from './router/PrivateRoute';
import { NAVIGATION_ROUTES } from './router/AppRouter';
import { HomePage } from './components/home/HomePage';
import { AuthExpiredSessionModal } from './pages/login/components/AuthExpiredSessionModal';
import FilterService from './services/filter';
import { ProtectedLayout } from './components/protectedLayout/protectedLayout';
import 'antd/dist/reset.css';

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile>();
  const authService = useAuthService();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const valuesFromUrl = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const tokenFromUrl = valuesFromUrl?.token;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
      },
    },
  });

  const logout = useCallback(() => {
    if (location.pathname !== '/restore') {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
      localStorage.removeItem('category');
      setProfile(null);
      if (location.pathname !== '/home' && location.pathname !== '/login') {
        setIsModalOpen(true);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (tokenFromUrl) {
      localStorage.setItem('token', tokenFromUrl);
    } else if (!localStorage.getItem('token')) {
      logout();
    }
  }, [tokenFromUrl, logout]);

  useEffect(() => {
    authService.loginStatus.on(AuthStatus.UNAUTHORIZED, logout);

    return () => {
      authService.loginStatus.off(AuthStatus.UNAUTHORIZED, logout);
    };
  }, [authService, logout]);

  useEffect(() => {
    setLoading(true);
    FilterService.getUserModules().then((res) => localStorage.setItem('permissions', JSON.stringify(res)));
    authService
      .getProfile()
      .then((profile) => {
        if (typeof profile === 'string') {
          throw Error('profile must be a object');
        } else {
          setProfile(profile);
          localStorage.setItem('user', JSON.stringify(profile));
        }
      })
      .catch((err) => {
        localStorage.removeItem('user');
        console.error(err);
        handleExit();
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setProfile(null);
    };
  }, [authService]);

  const handleExit = () => {
    navigate('/home');
    setIsModalOpen(false);
  };

  const appRoutes = (
    <ProtectedLayout user={profile} logout={logout}>
      <Routes>
        {NAVIGATION_ROUTES.map((route) => {
          const { element, ...otherProps } = route;
          return (
            <Route
              key={route.path as string}
              element={<PrivateRoute profile={profile}>{element}</PrivateRoute>}
              {...otherProps}
            ></Route>
          );
        })}
      </Routes>
    </ProtectedLayout>
  );

  return loading ? (
    <Loader />
  ) : (
    <>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/forget" element={<ForgetPassword />} />
          <Route path="/restore" element={<RestorePassword />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={profile ? <Navigate to={'/dashboard'} replace /> : <Navigate to="/home" />} />
          <Route path="/not-found" element={null} />
          <Route path="*" element={<PrivateRoute profile={profile}>{appRoutes}</PrivateRoute>} />
        </Routes>
        <AuthExpiredSessionModal isOpen={isModalOpen} handleExit={handleExit} />
      </QueryClientProvider>
    </>
  );
}
