import { Button, DatePicker, Form, Select } from 'antd';
import { removeEmptyObject } from '../../utils/removeEmptyObject';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import KazakhstanMap from './MapChart';
import { useSubcategories } from '../filter/hook';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import styles from './styles.module.scss';
import { format, parse } from 'date-fns';
import { useQuery } from 'react-query';
import MarketShareService from '../../services/marketShare';
import { useEffect, useState } from 'react';
import { Loader } from '../loader/Loader';
import { debounce } from '../../utils/debounce';
import { dateRange } from '../../utils/lastThreeMonths';

const CustomDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = CustomDatePicker;

export const DashboardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filtersForm] = Form.useForm();
  const { data: subcategoriesData, isFetching: subcategoriesIsFetching } = useSubcategories();
  const currentMonth = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyyMM');
  const [total, setTotal] = useState<number>(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const userLocalCompanyId = JSON.parse(localStorage.getItem('user'))?.company?.local_company_id;
  const valuesFromUrl = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const startMonth = valuesFromUrl?.months?.split(',')[0] || null;
  const endMonth = valuesFromUrl?.months?.split(',')[valuesFromUrl?.months?.split(',').length - 1] || null;
  const defaultEndDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const defaultStartDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const parseYearMonth = (yearMonth: string) => parse(yearMonth, 'yyyyMM', new Date());

  const initialValues = {
    monthsRange: valuesFromUrl?.months
      ? [parseYearMonth(startMonth), parseYearMonth(endMonth)]
      : [parse(currentMonth, 'yyyyMM', new Date()), parse(currentMonth, 'yyyyMM', new Date())],
    subcategory: valuesFromUrl?.subcategory ? valuesFromUrl?.subcategory?.split(',').map(Number) : [],
  };

  const { data, isFetching } = useQuery(['dashboard', location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;

    return MarketShareService.getMarketShareByCompany(
      {
        months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
        limit: 20,
        local_company: userLocalCompanyId,
        page: 1,
        size: 0,
        ...params,
      },
      { signal }
    );
  });

  const onSearch = (values: any) => {
    let normalized = {} as any;
    if (values.monthsRange) {
      const months = dateRange(format(values?.monthsRange[0], 'yyyy-MM'), format(values?.monthsRange[1], 'yyyy-MM'));
      delete values.monthsRange;
      values = {
        ...values,
        months: months,
      };
    }
    Object.keys(values).forEach((key) => {
      if (values[key] != null) {
        if (Array.isArray(values[key])) {
          normalized[key] = values[key].join(',');
        } else {
          normalized[key] = values[key];
        }
      }
    });
    navigate({
      pathname: location.pathname,
      search: `${qs.stringify(removeEmptyObject(normalized))}`,
    });
  };

  const debouncedOnSearch = debounce(onSearch, 1000);

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.additional?.[0]?.data?.[0]?.value_share);
    }
  }, [data]);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    if (Object.keys(params).length === 0) {
      filtersForm.resetFields(null);
    }
  }, [location.search, filtersForm]);

  return (
    <>
      <Form
        form={filtersForm}
        layout="inline"
        onValuesChange={(_, allValues) => debouncedOnSearch(allValues)}
        initialValues={initialValues}
        style={{ margin: 20 }}
      >
        <div className="user-filter-container">
          <Form.Item name="monthsRange" noStyle>
            <RangePicker
              format="YYYY-MM"
              picker="month"
              allowClear={true}
              style={{ width: '100%' }}
              className={styles.ant_calendar}
            />
          </Form.Item>

          <Form.Item name="subcategory">
            <Select
              options={subcategoriesData}
              allowClear={true}
              loading={subcategoriesIsFetching}
              placeholder="subcategory"
              style={{ width: '156px', marginLeft: 8 }}
            />
          </Form.Item>
        </div>
      </Form>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        {isFetching ? <Loader /> : <KazakhstanMap data={tableData || []} total={total || null} />}
      </div>
    </>
  );
};
