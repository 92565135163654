import React, { useEffect, useMemo, useState } from 'react';
import { notification, Pagination, PaginationProps, Table, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import qs from 'qs';

import { useNavigate, useLocation } from 'react-router-dom';
import ComparisonShareService from '../../../services/comparisonShare';
import { isTobacco } from '../../../utils/isTobacco';
import { Loader } from '../../../components/loader/Loader';
import { ComparisonShareCard } from './ComparisonShareCard';
import { useColumns } from './useColumns';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
  pageName: string;
  hiddenColumns?: any;
};

export const ComparisonShareTable = ({ pageName, hiddenColumns }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalInfo, setTotalInfo] = useState<any[]>([]);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });

  const [total, setTotal] = useState<number>(0);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;
  const { data, isFetching, error } = useQuery(['comparisonShareTable', pageName, location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    // const city = params?.city || 24;
    delete params?.city;
    switch (pageName) {
      case 'company':
        return ComparisonShareService.getComparisonShareByCompany(
          {
            period_1: params?.period_1,
            period_2: params?.period_2,
            // local_company_id: params?.local_company,
            // city_id: city,
            // subcategory_id: params?.subcategory,
            // common_stores: params.active_stores,
            ...params,
          },
          { signal }
        );

      case 'brand':
        return ComparisonShareService.getComparisonShareByBrand(
          {
            period_1: params?.period_1,
            period_2: params?.period_2,
            // local_company_id: params?.local_company,
            // city_id: city,
            // subcategory_id: params?.subcategory,
            // common_stores: params.active_stores,
            ...params,
          },
          { signal }
        );

      case 'barcode':
        return ComparisonShareService.getComparisonShareByBarcode(
          {
            period_1: params?.period_1,
            period_2: params?.period_2,
            // local_company_id: params?.local_company,
            // city_id: city,
            // subcategory_id: params?.subcategory,
            // common_stores: params.active_stores,
            ...params,
          },
          { signal }
        );
      default:
        return;
    }
  });

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    navigate({ pathname: location.pathname, search: queryParams.toString() });
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
      setTotalInfo(data?.data?.additional);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification(error?.response?.data?.period_1?.[0] || error?.response?.data?.period_2?.[0]);
        openNotification('Fill required fields');
        setTableData([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
        setTotalInfo([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotalInfo([]);
      }
    }

    return () => {
      setTableData([]);
      setTotalInfo([]);
    };
  }, [data, error, location.search]);

  const columnsParams = useMemo(
    () => ({ isTabletOrMobile, tableData, totalInfo, hiddenColumns }),
    [isTabletOrMobile, tableData, totalInfo, hiddenColumns]
  );
  const columns = useColumns(columnsParams);

  return (
    <>
      {!isTabletOrMobile ? (
        <Table
          columns={columns}
          dataSource={tableData}
          loading={isFetching}
          bordered
          size="middle"
          scroll={{ x: 1300 }}
          pagination={{
            pageSize: limit,
            defaultPageSize: 10,
            total: total,
            onChange: onPageChange,
            current: page,
            defaultCurrent: 1,
          }}
          className={styles.ant_table}
          summary={() => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text className={styles.total_bold}>Total</Text>
                  </Table.Summary.Cell>
                  {totalInfo[0]?.data?.map((item: any, index: number) => (
                    <>
                      {!hiddenColumns?.value?.value && (
                        <Table.Summary.Cell
                          index={1}
                          align="right"
                          className={index % 2 === 0 ? styles.column_gray : ''}
                        >
                          <span className={styles.summary_cell}>
                            <Text className={styles.total_bold}>
                              {`${item?.value}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ') || '--'}
                            </Text>
                            <Text className={styles.total_bold}>Share: {item?.value_share || '--'} %</Text>
                          </span>
                        </Table.Summary.Cell>
                      )}
                      {!hiddenColumns?.items?.value && (
                        <Table.Summary.Cell
                          index={1}
                          align="right"
                          className={index % 2 === 0 ? styles.column_gray : ''}
                        >
                          <span className={styles.summary_cell}>
                            <Text className={styles.total_bold}>
                              {`${item?.items}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ') || '--'}
                            </Text>
                            <Text className={styles.total_bold}>Share: {item?.items_share || '--'} %</Text>
                          </span>
                        </Table.Summary.Cell>
                      )}
                      {isTobacco() ||
                        (!hiddenColumns?.volume?.value && (
                          <Table.Summary.Cell
                            index={1}
                            align="right"
                            className={index % 2 === 0 ? styles.column_gray : ''}
                          >
                            <span className={styles.summary_cell}>
                              <Text className={styles.total_bold}>
                                {`${item?.volume}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ') || '--'}
                              </Text>
                              <Text className={styles.total_bold}>Share: {item?.volume_share || '--'} %</Text>
                            </span>
                          </Table.Summary.Cell>
                        ))}
                      {!hiddenColumns?.avg_price?.value && (
                        <Table.Summary.Cell
                          index={1}
                          align="right"
                          className={index % 2 === 0 ? styles.column_gray : ''}
                        >
                          <Text className={styles.total_bold}>
                            {`${item?.avg_price}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ') || '--'}
                          </Text>
                        </Table.Summary.Cell>
                      )}
                      {!hiddenColumns?.numeric_distribution?.value && (
                        <Table.Summary.Cell
                          index={2}
                          align="right"
                          className={index % 2 === 0 ? styles.column_gray : ''}
                        >
                          <Text className={styles.total_bold}>{item?.numeric_distribution || '--'}</Text>
                        </Table.Summary.Cell>
                      )}
                      {!hiddenColumns?.stores?.value && (
                        <Table.Summary.Cell
                          index={2}
                          align="right"
                          className={index % 2 === 0 ? styles.column_gray : ''}
                        >
                          <Text className={styles.total_bold}>{item?.stores || '--'}</Text>
                        </Table.Summary.Cell>
                      )}
                    </>
                  ))}
                </Table.Summary.Row>
              </>
            );
          }}
        />
      ) : isFetching ? (
        <Loader />
      ) : (
        tableData && (
          <div style={{ marginTop: 16 }}>
            <ComparisonShareCard tableData={tableData} isTabletOrMobile={isTabletOrMobile} totalData={totalInfo} />
            <Pagination
              size="small"
              onChange={onPageChange}
              total={total}
              current={page}
              pageSize={limit}
              defaultPageSize={10}
              defaultCurrent={1}
            />
          </div>
        )
      )}
    </>
  );
};
