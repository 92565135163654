import { ComponentType, FC } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { Profile } from '../models/authModel';

type Props = {
  // component: ComponentType<{ profile: Profile; logout?: () => void }>;
  loading?: boolean;
  profile: Profile;
  logout?: () => void;
  [key: string]: any;
};

export const PrivateRoute: React.FC<React.PropsWithChildren<Props>> = ({ profile, children }) => {
  if (!profile) {
    return null;
  }
  return <>{children}</>;
  // return !loading ? (
  //   <Route
  //     {...rest}
  //     render={
  //       (props) => (profile && profile !== null ? <Component profile={profile} logout={logout} {...props} /> : null)

  //       // <Redirect
  //       //   to={{
  //       //     pathname: '/login',
  //       //   }}
  //       // />
  //     }
  //   />
  // ) : null;
};
