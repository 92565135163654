import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Drawer, Radio, RadioChangeEvent, Row, Tabs } from 'antd';
import qs from 'qs';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';

import { TrendsCategory } from './TrendsCategory';
import { CustomFilter } from '../../components/filter/CustomFilter';
import { RadioGroupMenu } from '../../components/RadioGroupMenu/RadioGroupMenu';
import { FilterOutlined, LineChartOutlined, TableOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import TrendsService from '../../services/trends';
import { dateRange, openNotification } from '../../utils/lastThreeMonths';
import { isTobacco } from '../../utils/isTobacco';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import styles from './styles.module.scss';
import { downloadFile } from '../../utils/downloadFile';

export const TrendsReportsPage = () => {
  const { type, tab } = useParams<{ type: string; tab: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [currentTab, setCurrentTab] = useState<string>(tab || 'value');
  const [openFilter, setOpenFilter] = useState(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  // const [tags, setTags] = useState<Tag[]>([]);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const defaultEndDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const defaultStartDate = format(new Date().setMonth(new Date().getMonth() - 6), 'yyyy-MM');
  const [page, setPage] = useState<string>('company');
  const [mode, setMode] = useState<string>('table');
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);

  const onChangePage = ({ target: { value } }: RadioChangeEvent) => {
    setPage(value);
    navigate({
      pathname: `/trends/reports/${value}/${currentTab}`,
      search: location.search,
    });
  };

  const onChangeMode = (e: RadioChangeEvent) => {
    setMode(e.target?.value);
  };

  const operations = (
    <Row>
      <Col>
        <Radio.Group defaultValue="table" buttonStyle="solid" onChange={onChangeMode}>
          <Radio.Button value="table"> {isTabletOrMobile ? <TableOutlined /> : 'Table'}</Radio.Button>
          <Radio.Button value="graphic">{isTabletOrMobile ? <LineChartOutlined /> : 'Graphic'}</Radio.Button>
        </Radio.Group>
      </Col>
    </Row>
  );

  useEffect(() => {
    if (location.pathname.includes('company')) {
      setPage('company');
    } else if (location.pathname.includes('brand')) {
      setPage('brand');
    } else if (location.pathname.includes('barcode')) {
      setPage('barcode');
    }
    return () => {
      setPage('company');
    };
  }, [location.pathname]);

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const tabs = useMemo(
    () => [
      { label: 'Value', key: 'value', children: <TrendsCategory tab={currentTab} pageName={type || ''} mode={mode} /> },
      { label: 'Item', key: 'item', children: <TrendsCategory tab={currentTab} pageName={type || ''} mode={mode} /> },
      !isTobacco() && {
        label: 'Volume',
        key: 'volume',
        children: <TrendsCategory tab={currentTab} pageName={type || ''} mode={mode} />,
      },
    ],
    [currentTab, mode, type]
  );

  const onChange = (key: string) => {
    setCurrentTab(key);
    navigate({
      pathname: `/trends/reports/${type}/${key}`,
      search: location.search,
    });
  };

  const handleDownloadFile = () => {
    setExportLoading(true);
    TrendsService.exportReportsByType(type === 'company' ? 'local_company' : type, {
      measure: tab,
      months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
      city_id: params?.city,
      common_stores: params.active_stores,
      subcategory_id: params?.subcategory,
      local_company_id: params?.local_company,
      brand_id: params.brand,
      ...params,
    })
      .then((res) => {
        const fileName =
          `trends_reports_by_${type}_${dateRange(defaultStartDate, defaultEndDate).join(',')}` || 'detailed_report';
        downloadFile(res.data, fileName);
      })
      .catch((err) => openNotification('Something went wrong', 'error'))
      .finally(() => setExportLoading(false));
  };

  return (
    <>
      <div className={styles.company_layout_content}>
        <div className={styles.top_bar}>
          <div className={styles.wrapper}>
            {!isTabletOrMobile && (
              <div className={styles.title}>
                <RadioGroupMenu onChange={onChangePage} value={page} />
              </div>
            )}
            {isTabletOrMobile && (
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
                <div>
                  <Drawer
                    title=""
                    placement="left"
                    onClose={hide}
                    open={openFilter}
                    width={isTabletOrMobile ? '320' : undefined}
                  >
                    <CustomFilter type={type} onFilterChange={onFilterChange} pageName="trends" onCancel={hide} />
                  </Drawer>
                  <Button
                    type="default"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  />
                </div>
              </div>
            )}
            {!isTabletOrMobile && (
              <>
                <div style={{ marginLeft: '15px' }}>
                  <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                    <CustomFilter type={type} onFilterChange={onFilterChange} pageName="trends" onCancel={hide} />
                  </Drawer>
                  <Button
                    type="default"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  >
                    &nbsp;&nbsp;Filter
                  </Button>
                </div>
              </>
            )}
          </div>
          <Button type="default" className={styles.export_btn} onClick={handleDownloadFile} loading={exportLoading}>
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        </div>
        <Tabs defaultActiveKey={currentTab} onChange={onChange} items={tabs} tabBarExtraContent={operations} />
      </div>
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </>
  );
};
