import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { Button, Tag, Tooltip } from 'antd';
import { CheckCircleOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';

interface DataType {
  key: React.Key;
  name: string;
}

export const useUserColumns = ({
  setIsUserActive,
  setId,
  setIsUserEditOpen,
  handleOpenModal,
}: any): ColumnsType<DataType> => {
  const navigate = useNavigate();
  return [
    {
      title: 'User',
      width: 225,
      render: (value: any) => value?.first_name + ' ' + value?.last_name,
    },
    {
      title: 'Username',
      width: 225,
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Role',
      width: 225,
      render: (value: any) => value?.role?.name,
    },
    {
      title: 'Email',
      width: 225,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      width: 225,
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'status',
      width: 225,
      render: (value: any) => (value?.is_active ? <Tag color="green">Active</Tag> : <Tag color="red">Not active</Tag>),
    },
    {
      title: 'Action',
      key: 'action',
      render: (value: any) => (
        <>
          <Tooltip placement="top" title="Edit">
            <Button
              type="text"
              onClick={() => {
                setId(value?.id);
                setIsUserEditOpen(true);
              }}
            >
              <EditOutlined />
            </Button>
          </Tooltip>

          {value.is_active ? (
            <Tooltip placement="top" title="Block user">
              <Button
                type="text"
                onClick={() => {
                  setIsUserActive(false);
                  setId(value?.id);
                  handleOpenModal();
                }}
              >
                <StopOutlined />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip placement="top" title="Unblock user">
              <Button
                type="text"
                onClick={() => {
                  setIsUserActive(true);
                  setId(value?.id);
                  handleOpenModal();
                }}
              >
                <CheckCircleOutlined />
              </Button>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
};
