import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Drawer, Dropdown, Menu, Tabs } from 'antd';
import { FilterOutlined, InfoCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';

import { CustomFilter } from '../../components/filter/CustomFilter';
import CoExistenceService from '../../services/coExistance';
// import { downloadFile } from '../../utils/downloadFile';
import { SalesTable } from './components/SalesTable';
import { StockTable } from './components/StockTable';
import { InformationModal } from './components/InformationModal';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import { openNotification } from '../../utils/lastThreeMonths';
import styles from './styles.module.scss';

const ExportMenuItems = (onDownload: (type: 'stock' | 'sale') => void) => (
  <Menu>
    <Menu.Item key="sale">
      <div onClick={() => onDownload('sale')}>Sale</div>
    </Menu.Item>
    <Menu.Item key="stock">
      <div onClick={() => onDownload('stock')}>Stock</div>
    </Menu.Item>
  </Menu>
);

export const CoExistencePage = () => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [openFilter, setOpenFilter] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>('sales');
  // const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);
  const defaultDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM')
    .split('-')
    .join('');
  const tabs = useMemo(
    () => [
      {
        label: 'sales',
        key: 'sales',
        children: <SalesTable date={params?.months} />,
      },
      {
        label: 'stock',
        key: 'stock',
        children: <StockTable />,
      },
    ],
    [params?.months]
  );

  const onChange = (key: string) => {
    setCurrentTab(key);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const handleDownloadFile = (type: 'stock' | 'sale') => {
    setExportLoading(true);
    CoExistenceService.exportByType(type, {
      months: params?.months || defaultDate,
      brand: params?.brand,
      ...params,
    })
      .then((res) => {
        setIsOpenDonwloads(true);
        openNotification('File added to downloads folder', 'success');
        // downloadFile(res.data, `report_${type}_${params?.months || defaultDate}` || 'coexistence_report');
      })
      .finally(() => setExportLoading(false));
  };

  return (
    <div className={styles.company_layout_content}>
      <div className={styles.top_bar}>
        <div className={styles.wrapper}>
          {isTabletOrMobile && (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
              <div>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter pageName="co-existence" type="brand" onFilterChange={onFilterChange} onCancel={hide} />
                </Drawer>
                <Button
                  type="default"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                />
              </div>
            </div>
          )}
          {!isTabletOrMobile && (
            <>
              <div style={{ marginLeft: '15px' }}>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter pageName="co-existence" type="brand" onFilterChange={onFilterChange} onCancel={hide} />
                </Drawer>
                <Button
                  type="default"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                >
                  &nbsp;&nbsp;Filter
                </Button>
              </div>
            </>
          )}
        </div>
        <Button
          size="large"
          shape="circle"
          type="link"
          icon={<InfoCircleOutlined />}
          onClick={() => setOpenModal(true)}
        />
        &nbsp;&nbsp;&nbsp;
        <Dropdown overlay={ExportMenuItems(handleDownloadFile)}>
          <Button type="default" className={styles.export_btn} loading={exportLoading}>
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        </Dropdown>
      </div>
      {isTabletOrMobile ? (
        <Tabs defaultActiveKey={currentTab} onChange={onChange} items={tabs} />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <SalesTable date={params?.months} />
          <StockTable />
        </div>
      )}
      <InformationModal open={openModal} close={handleCloseModal} isMobile={isTabletOrMobile} />
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </div>
  );
};
