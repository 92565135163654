import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import AuthService from '../../services/auth';
import './login.css';

export const ForgetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const onFinish = (values: any) => {
    setLoading(true);
    AuthService.resetPassword(values.username)
      .then((res) => {
        if (res) {
          AuthService.getUser()
            .then((profile) => {
              if (typeof profile === 'string') {
                throw Error('profile must be a object');
              } else {
                navigate('/login');
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        setError('Incorrect login');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="container">
      <div className="login-form">
        <Form name="normal_login" initialValues={{ remember: true }} onFinish={onFinish}>
          <div className="logo-wrapper">
            <div className="logo-svg">
              <svg width="142" height="45" viewBox="0 0 142 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.8965 25.04C10.8965 23.2053 11.2378 21.5947 11.9205 20.208C12.6245 18.8213 13.5738 17.7547 14.7685 17.008C15.9632 16.2613 17.2965 15.888 18.7685 15.888C19.9418 15.888 21.0085 16.1333 21.9685 16.624C22.9498 17.1147 23.7178 17.776 24.2725 18.608V10.32H29.7445V34H24.2725V31.44C23.7605 32.2933 23.0245 32.976 22.0645 33.488C21.1258 34 20.0272 34.256 18.7685 34.256C17.2965 34.256 15.9632 33.8827 14.7685 33.136C13.5738 32.368 12.6245 31.2907 11.9205 29.904C11.2378 28.496 10.8965 26.8747 10.8965 25.04ZM24.2725 25.072C24.2725 23.7067 23.8885 22.6293 23.1205 21.84C22.3738 21.0507 21.4565 20.656 20.3685 20.656C19.2805 20.656 18.3525 21.0507 17.5845 21.84C16.8378 22.608 16.4645 23.6747 16.4645 25.04C16.4645 26.4053 16.8378 27.4933 17.5845 28.304C18.3525 29.0933 19.2805 29.488 20.3685 29.488C21.4565 29.488 22.3738 29.0933 23.1205 28.304C23.8885 27.5147 24.2725 26.4373 24.2725 25.072Z"
                  fill="#1D1D1F"
                />
                <path
                  d="M32.6152 25.04C32.6152 23.2053 32.9566 21.5947 33.6392 20.208C34.3432 18.8213 35.2926 17.7547 36.4872 17.008C37.6819 16.2613 39.0152 15.888 40.4872 15.888C41.7459 15.888 42.8446 16.144 43.7832 16.656C44.7432 17.168 45.4792 17.84 45.9912 18.672V16.144H51.4632V34H45.9912V31.472C45.4579 32.304 44.7112 32.976 43.7512 33.488C42.8126 34 41.7139 34.256 40.4552 34.256C39.0046 34.256 37.6819 33.8827 36.4872 33.136C35.2926 32.368 34.3432 31.2907 33.6392 29.904C32.9566 28.496 32.6152 26.8747 32.6152 25.04ZM45.9912 25.072C45.9912 23.7067 45.6072 22.6293 44.8392 21.84C44.0926 21.0507 43.1752 20.656 42.0872 20.656C40.9992 20.656 40.0712 21.0507 39.3032 21.84C38.5566 22.608 38.1832 23.6747 38.1832 25.04C38.1832 26.4053 38.5566 27.4933 39.3032 28.304C40.0712 29.0933 40.9992 29.488 42.0872 29.488C43.1752 29.488 44.0926 29.0933 44.8392 28.304C45.6072 27.5147 45.9912 26.4373 45.9912 25.072Z"
                  fill="#1D1D1F"
                />
                <path
                  d="M65.374 29.36V34H62.59C60.606 34 59.0593 33.52 57.95 32.56C56.8407 31.5787 56.286 29.9893 56.286 27.792V20.688H54.11V16.144H56.286V11.792H61.758V16.144H65.342V20.688H61.758V27.856C61.758 28.3893 61.886 28.7733 62.142 29.008C62.398 29.2427 62.8247 29.36 63.422 29.36H65.374Z"
                  fill="#1D1D1F"
                />
                <path
                  d="M86.054 16.144V34H80.582V31.568C80.0273 32.3573 79.27 32.9973 78.31 33.488C77.3713 33.9573 76.326 34.192 75.174 34.192C73.8086 34.192 72.6033 33.8933 71.558 33.296C70.5127 32.6773 69.702 31.792 69.126 30.64C68.55 29.488 68.262 28.1333 68.262 26.576V16.144H73.702V25.84C73.702 27.0347 74.0113 27.9627 74.63 28.624C75.2487 29.2853 76.0807 29.616 77.126 29.616C78.1927 29.616 79.0353 29.2853 79.654 28.624C80.2727 27.9627 80.582 27.0347 80.582 25.84V16.144H86.054Z"
                  fill="#1D1D1F"
                />
                <path
                  d="M112.768 15.952C114.987 15.952 116.747 16.624 118.048 17.968C119.371 19.312 120.032 21.1787 120.032 23.568V34H114.592V24.304C114.592 23.152 114.283 22.2667 113.664 21.648C113.067 21.008 112.235 20.688 111.168 20.688C110.102 20.688 109.259 21.008 108.64 21.648C108.043 22.2667 107.744 23.152 107.744 24.304V34H102.304V24.304C102.304 23.152 101.995 22.2667 101.376 21.648C100.779 21.008 99.9472 20.688 98.8805 20.688C97.8138 20.688 96.9712 21.008 96.3525 21.648C95.7552 22.2667 95.4565 23.152 95.4565 24.304V34H89.9845V16.144H95.4565V18.384C96.0111 17.6373 96.7365 17.0507 97.6325 16.624C98.5285 16.176 99.5418 15.952 100.672 15.952C102.016 15.952 103.211 16.24 104.256 16.816C105.323 17.392 106.155 18.2133 106.752 19.28C107.371 18.2987 108.214 17.4987 109.28 16.88C110.347 16.2613 111.51 15.952 112.768 15.952Z"
                  fill="#1D1D1F"
                />
                <path
                  d="M132 30C132 32.2091 130.21 34 128 34C125.791 34 124 32.2091 124 30C124 27.7909 125.791 26 128 26C130.21 26 132 27.7909 132 30Z"
                  fill="#EC1E1E"
                />
              </svg>
            </div>
          </div>

          <Form.Item name="username" rules={[{ required: true, message: 'Please input your email!' }]}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              disabled={loading}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Send password
          </Button>
          {loading && <p>Please wait...</p>}
          {error && <p className="error">{error}</p>}
        </Form>
      </div>
    </div>
  );
};
