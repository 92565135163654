import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input, notification, Tabs, TreeSelect, Typography } from 'antd';

import FilterService from '../../../services/filter';
import PermissionsService from '../../../services/permissions';
import { SalesFormItems } from '../components/SalesFormItems';
import { ShareByCitiesFormItemsNew } from '../components/ShareByCitiesFormItemsNew';
import { StoreFormItemsNew } from '../components/StoreFormItemsNew';
import { AnalysisFormItemsNew } from '../components/AnalysisFormItemsNew';
import { ManageFormItemsNew } from '../components/ManageFormItemsNew';
import styles from './styles.module.scss';

const { Text } = Typography;
const { TabPane } = Tabs;

type Props = {
  isModal?: boolean;
  link?: string;
  close?: () => void;
};

export const CreateRole = ({ isModal, link, close }: Props) => {
  const { roleId } = useParams<{ roleId: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [regions, setRegions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [storeValue, setStoreValue] = useState<any>();
  const userCompanyId = JSON.parse(localStorage.getItem('user'))?.company?.id;
  const { setFieldValue } = form;

  const onNavigateTable = () => {
    if (isModal) {
      navigate(link);
      close();
    } else {
      navigate('/permissions/roles');
    }
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.success({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  const onFinish = (values: any) => {
    const permissions: any = [];
    Object.entries(values).map(([key, value]) => {
      if (key !== 'name' && key !== 'regions') {
        if (key === 'sales_by_stores' && value) {
          permissions.push({
            module: 'sales_by_stores',
          });
          permissions.push({
            module: 'store_statistics',
          });
        } else if (value) {
          permissions.push({
            module: key,
          });
        }
      }
      return permissions;
    });

    if (roleId) {
      PermissionsService.editRoleById(roleId, {
        regions: values.regions,
        name: values.name,
        permissions: permissions,
        company: userCompanyId,
      }).then((res) => {
        openNotification('Role changed!');
        navigate('/permissions/roles');
      });
    } else {
      PermissionsService.createRole({
        company: userCompanyId,
        regions: values?.regions,
        name: values?.name,
        permissions: permissions,
      }).then((res) => {
        openNotification('Role created!');
        if (isModal) {
          navigate(link);
          close();
        } else {
          navigate('/permissions/roles');
        }
      });
    }
  };

  const getRegions = useCallback(() => {
    setLoading(true);
    FilterService.getCities(true)
      .then((res) => {
        setRegions(res.data.results);
      })
      .catch((err) => setRegions([]))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getRegions();
    return () => {
      setRegions([]);
    };
  }, []);

  useEffect(() => {
    if (roleId) {
      PermissionsService.getRoleById(roleId).then((res) => {
        const permissions = res.data.permissions
          ?.map((item) => {
            return { [item]: true };
          })
          .reduce((obj: any, item: any) => Object.assign(obj, item), {});
        form.setFieldsValue({
          ...res.data,
          ...permissions,
          regions: res?.data?.regions.map(String),
        });
        setStoreValue(form.getFieldValue('sales_by_stores') || false);
      });
    }
  }, [roleId, form]);

  return (
    <div>
      {!isModal && (
        <div className={styles.title}>
          <Text>{roleId ? 'Edit' : 'Create'} role</Text>
        </div>
      )}

      <Form
        form={form}
        onFinish={onFinish}
        className={styles.form}
        labelCol={{ span: isModal ? 6 : 3 }}
        wrapperCol={{ span: isModal ? 12 : 8 }}
      >
        <div className={styles.actions}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button type="default" htmlType="button" onClick={onNavigateTable} style={{ marginLeft: '16px' }}>
            Cancel
          </Button>
        </div>

        <div>
          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Role name</Typography>
            <Form.Item name="name" rules={[{ required: true }]} labelAlign="left">
              <Input />
            </Form.Item>
          </div>

          <div>
            <Typography>Region</Typography>
            <Form.Item name="regions" labelAlign="left" rules={[{ required: true }]}>
              <TreeSelect
                treeData={regions}
                treeCheckable={true}
                labelInValue={false}
                placeholder="Please select"
                maxTagCount="responsive"
                loading={loading}
                allowClear
              />
            </Form.Item>
          </div>

          <Tabs className="card-tabs">
            <TabPane tab="Sales" key="sales" forceRender>
              <SalesFormItems isModal={isModal} />
            </TabPane>
            <TabPane tab="Share by cities" key="share" forceRender>
              <ShareByCitiesFormItemsNew isModal={isModal} />
            </TabPane>
            <TabPane tab="Stores" key="stores" forceRender>
              <StoreFormItemsNew isModal={isModal} setFieldValue={setFieldValue} value={storeValue} />
            </TabPane>
            <TabPane tab="Analysis" key="analysis" forceRender>
              <AnalysisFormItemsNew isModal={isModal} permission={userCompanyId} />
            </TabPane>
            <TabPane tab="Manage" key="manage" forceRender>
              <ManageFormItemsNew isModal={isModal} />
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </div>
  );
};
