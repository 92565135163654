import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { notification, Pagination, PaginationProps, Table, Typography } from 'antd';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import qs from 'qs';

import ComparisonService from '../../../services/comparison';
import DownRedIcon from '../../../assets/down-red.svg';
import UpGreenIcon from '../../../assets/up-green.svg';
import { ChangeInfoModal } from '../../trends/components/InformationModal';
import { Loader } from '../../../components/loader/Loader';
import { ComparisonCard } from './ComparisonCard';
import { useColumns } from './useColumns';

import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
  tab: string;
  pageName: string;
};

export const ComparisonTable = ({ tab, pageName }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [periodLabels, setPeriodLabels] = useState<string[]>([]);
  const [totalData, setTotalData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [isShareActive, setIsShareActive] = useState<boolean>(true);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;
  const { data, isFetching, error } = useQuery(['comparisonTable', tab, pageName, location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    switch (pageName) {
      case 'company':
        return ComparisonService.getComparisonTableByCompany(
          {
            measure: tab,
            period_1_after: params?.period_1_after,
            period_1_before: params?.period_1_before,
            period_2_after: params?.period_2_after,
            period_2_before: params?.period_2_before,
            local_company: params?.local_company,
            ...params,
          },
          { signal }
        );

      case 'brand':
        return ComparisonService.getComparisonTableByBrand(
          {
            measure: tab,
            period_1_after: params?.period_1_after,
            period_1_before: params?.period_1_before,
            period_2_after: params?.period_2_after,
            period_2_before: params?.period_2_before,
            brand: params?.brand,
            ...params,
          },
          { signal }
        );

      case 'barcode':
        return ComparisonService.getComparisonTableByBarcode(
          {
            measure: tab,
            period_1_after: params?.period_1_after,
            period_1_before: params?.period_1_before,
            period_2_after: params?.period_2_after,
            period_2_before: params?.period_2_before,
            barcode: params?.barcode_product_name,
            ...params,
          },
          { signal }
        );
      default:
        return;
    }
  });

  // const getData = useCallback(() => {
  //   queryClient.refetchQueries('trendsTable', { active: true });
  // }, [queryClient]);

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    navigate({ pathname: location.pathname, search: queryParams.toString() });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
      setPeriodLabels(data?.data?.additional?.periods);
      setTotalData(data?.data?.additional?.total[0]?.data);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification(
          `period_1: ${error?.response?.data?.period_1?.[0]} \n period_2:${error?.response?.data?.period_2?.[0]}`
        );
        setTableData([]);
        setTotalData([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
      }
    }

    return () => {
      setTableData([]);
    };
  }, [data, error, location.search]);

  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: isTabletOrMobile ? '11px' : '14px',
  };

  const columnsParams = useMemo(
    () => ({ pageName, isTabletOrMobile, tab, tableData, setOpenModal, isShareActive, setIsShareActive, periodLabels }),
    [pageName, isTabletOrMobile, tab, tableData, setOpenModal, isShareActive, setIsShareActive, periodLabels]
  );
  const columns = useColumns(columnsParams);

  return (
    <>
      {!isTabletOrMobile ? (
        <Table
          columns={columns?.filter((item) => !item['hidden']) || columns}
          dataSource={tableData}
          loading={isFetching}
          bordered
          size="middle"
          pagination={{
            pageSize: limit,
            defaultPageSize: 10,
            total: total,
            onChange: onPageChange,
            current: page,
            defaultCurrent: 1,
          }}
          className={styles.ant_table}
          scroll={{ x: 1300 }}
          summary={() => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text className={styles.total_bold}>Total</Text>
                  </Table.Summary.Cell>
                  {pageName === 'barcode' && <Table.Summary.Cell index={1} />}

                  {totalData?.map((item: any, index: number) => (
                    <>
                      <Table.Summary.Cell index={1} align="right">
                        <span className={styles.summary_cell}>
                          <Text className={styles.total_bold}>
                            {`${item?.linear?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}` || '--'}
                          </Text>
                          <Text className={styles.total_bold}>Share: {item?.share || '--'} %</Text>
                        </span>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={1} align="right">
                        <span className={styles.summary_cell}>
                          <Text className={styles.total_bold}>
                            {`${item?.stores?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}` || '--'}
                          </Text>
                        </span>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={1} align="right">
                        {isShareActive ? (
                          <span className={styles.summary_cell}>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: item?.share_absolute === '--' ? 'flex-end' : 'space-between',
                              }}
                            >
                              {item?.share_absolute === '--' ? (
                                ''
                              ) : (
                                <img src={item?.share_absolute > 0 ? UpGreenIcon : DownRedIcon} alt="red" />
                              )}
                              <p style={defaultStyles}>Absolute: {item?.share_absolute} %</p>
                            </span>

                            <span
                              style={{
                                display: 'flex',
                                justifyContent: item?.share_relative === '--' ? 'flex-end' : 'space-between',
                              }}
                            >
                              {item?.share_relative === '--' ? (
                                ''
                              ) : (
                                <img src={item?.share_relative > 0 ? UpGreenIcon : DownRedIcon} alt="green" />
                              )}
                              <p
                                style={{
                                  textAlign: 'right',
                                  ...defaultStyles,
                                }}
                              >
                                Relative: {item?.share_relative} %
                              </p>
                            </span>
                          </span>
                        ) : (
                          <span className={styles.summary_cell}>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: item?.absolute === '--' ? 'flex-end' : 'space-between',
                              }}
                            >
                              {item?.absolute === '--' ? (
                                ''
                              ) : (
                                <img src={item?.absolute > 0 ? UpGreenIcon : DownRedIcon} alt="red" />
                              )}
                              <p style={defaultStyles}>Absolute: {item?.absolute} %</p>
                            </span>

                            <span
                              style={{
                                display: 'flex',
                                justifyContent: item?.relative === '--' ? 'flex-end' : 'space-between',
                              }}
                            >
                              {item?.relative === '--' ? (
                                ''
                              ) : (
                                <img src={item?.relative > 0 ? UpGreenIcon : DownRedIcon} alt="green" />
                              )}
                              <p
                                style={{
                                  textAlign: 'right',
                                  ...defaultStyles,
                                }}
                              >
                                Relative: {item?.relative} %
                              </p>
                            </span>
                          </span>
                        )}
                      </Table.Summary.Cell>
                    </>
                  ))}
                </Table.Summary.Row>
              </>
            );
          }}
        />
      ) : isFetching ? (
        <Loader />
      ) : (
        tableData && (
          <>
            <ComparisonCard
              tableData={tableData}
              pageName={pageName}
              tab={tab}
              isTabletOrMobile={isTabletOrMobile}
              periodLabels={periodLabels}
              totalData={totalData}
            />
            <Pagination
              size="small"
              onChange={onPageChange}
              total={total}
              current={page}
              pageSize={limit}
              defaultPageSize={10}
              defaultCurrent={1}
            />
          </>
        )
      )}
      <ChangeInfoModal open={openModal} close={handleCloseModal} />
    </>
  );
};
