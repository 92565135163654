import React, { useEffect, useMemo, useState } from 'react';
import { notification, PaginationProps, Table, Typography } from 'antd';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import qs from 'qs';

import { useNavigate, useLocation } from 'react-router-dom';
import ComparisonProductService from '../../../services/comparisonProduct';
import { useColumns } from './useColumns';
import styles from './styles.module.scss';

const { Text } = Typography;

export const ComparisonProductTable = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalInfo, setTotalInfo] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;

  const { data, isFetching, error } = useQuery(['comparisonProductTable', location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    return ComparisonProductService.getComparisonProduct({
      period_after: params?.period_after,
      period_before: params?.period_before,
      barcode_1: params?.barcode_1,
      barcode_2: params?.barcode_2,
      ...params,
    });
  });

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    navigate({ pathname: location.pathname, search: queryParams.toString() });
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotalInfo(data?.data?.additional);
      setTotalCount(data?.data?.count);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification('Fill required fields');
        setTableData([]);
        setTotalInfo([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
        setTotalInfo([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotalInfo([]);
      }
    }

    return () => {
      setTableData([]);
      setTotalInfo([]);
    };
  }, [data, error, location.search]);

  const columnsParams = useMemo(
    () => ({ tableData, totalInfo, isMobile: isTabletOrMobile }),
    [tableData, totalInfo, isTabletOrMobile]
  );
  const columns = useColumns(columnsParams);

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isFetching}
        pagination={{
          pageSize: limit,
          defaultPageSize: 10,
          total: totalCount,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
        bordered
        size="middle"
        scroll={{ x: '100%' }}
        summary={() => {
          return (
            <>
              {totalInfo.length !== 0 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text className={styles.total_bold}>Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    {isTabletOrMobile ? (
                      <>
                        <p style={{ fontSize: 11, margin: 0, padding: 0 }}>
                          Supply: &nbsp;
                          {totalInfo[0]?.data[0]?.supply_barcode_1 || '--'}
                        </p>
                        <p style={{ fontSize: 11 }}>
                          Sale: &nbsp;
                          {totalInfo[0]?.data[0]?.sale_barcode_1 || '--'}
                        </p>
                      </>
                    ) : (
                      <Text className={styles.total_bold}>{totalInfo[0]?.data[0]?.supply_barcode_1 || '--'}</Text>
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    {isTabletOrMobile ? (
                      <>
                        <p style={{ fontSize: 11, margin: 0, padding: 0 }}>
                          Supply:
                          {totalInfo[0]?.data[0]?.supply_barcode_2 || '--'}
                        </p>
                        <p style={{ fontSize: 11 }}>
                          Sale:
                          {totalInfo[0]?.data[0]?.sale_barcode_2 || '--'}
                        </p>
                      </>
                    ) : (
                      <Text className={styles.total_bold}>{totalInfo[0]?.data[0]?.supply_barcode_2 || '--'}</Text>
                    )}
                  </Table.Summary.Cell>
                  {!isTabletOrMobile && (
                    <>
                      <Table.Summary.Cell index={3}>
                        <Text className={styles.total_bold}>{totalInfo[0]?.data[0]?.supply_difference || '--'}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text className={styles.total_bold}>
                          {totalInfo[0]?.data[0]?.supply_difference_percent || '--'}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text className={styles.total_bold}>{totalInfo[0]?.data[0]?.sale_barcode_1 || '--'}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        <Text className={styles.total_bold}>{totalInfo[0]?.data[0]?.sale_barcode_2 || '--'}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7}>
                        <Text className={styles.total_bold}>{totalInfo[0]?.data[0]?.sale_difference || '--'}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8}>
                        <Text className={styles.total_bold}>
                          {totalInfo[0]?.data[0]?.sale_difference_percent || '--'}
                        </Text>
                      </Table.Summary.Cell>
                    </>
                  )}
                </Table.Summary.Row>
              )}
            </>
          );
        }}
      />
    </>
  );
};
